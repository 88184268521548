import type { ElementType } from 'react'

import { ScreenReaderHidden } from '../ScreenReaderHidden'
import { BodyM, type TypographyProps } from '../Typography'
import { VisuallyHidden } from '../VisuallyHidden'

export interface AccessibleTextProps extends TypographyProps {
  accessibleText?: string
  shouldDisplay?: boolean
  classNames?: string
  as?: ElementType
  text: string
}

export const AccessibleText = ({
  as = BodyM as ElementType,
  shouldDisplay = true,
  accessibleText,
  classNames,
  text,
  ...props
}: AccessibleTextProps) => {
  if (!shouldDisplay) return null
  return (
    <>
      <ScreenReaderHidden className={classNames} as={as} html={text} {...props} />
      {accessibleText && <VisuallyHidden as="span">{accessibleText}</VisuallyHidden>}
    </>
  )
}
