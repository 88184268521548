import { forwardRef, type ElementType } from 'react'

import type {
  PolymorphicComponentProps,
  PolymorphicComponentWithRef,
  PolymorphicRef,
} from '@/legacy/models/PolymorphicComponent'

const ScreenReaderHidden: PolymorphicComponentWithRef = forwardRef(
  <T extends ElementType = 'span'>(
    { as, ...props }: PolymorphicComponentProps<T>,
    ref?: PolymorphicRef<T>,
  ) => {
    const Component = as || 'span'
    return <Component {...props} ref={ref} aria-hidden />
  },
)
ScreenReaderHidden.displayName = 'ScreenReaderHidden'
export default ScreenReaderHidden
