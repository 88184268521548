import cx from 'classnames'

import { useSelectTabEvent } from '@/legacy/core/analytics/hooks/useSelectTabEvent'
import { sanitizeHTMLContent } from '@/legacy/core/utils/dom/string'

import { Image } from '../Image'
import { Typography } from '../Typography'

import { useCarouselSlider } from './core/useCarouselSlider'
import type { ProductSolutionCarouselProps } from './ProductSolutionCarousel'
import * as Styled from './styles'

type ProductSolutionSliderProps = Pick<ProductSolutionCarouselProps, 'items' | 'backgroundColor'>

export const ProductSolutionSlider = ({ items, backgroundColor }: ProductSolutionSliderProps) => {
  const { handleChangeSlide, currentCardIndex, handleKeyDown, setCurrentTabRef } =
    useCarouselSlider({
      itemsLength: items.length,
    })

  if (!items || items.length === 0) return null

  const currentCard = items[currentCardIndex]

  return (
    <>
      <Styled.Legend role="tablist" tabIndex={-1} aria-orientation="horizontal">
        <Styled.LegendWrapper>
          {items.map((item, index) => (
            <Styled.LegendButton
              id={`tab-header-${index}`}
              className={cx({ ['--selected']: currentCardIndex === index })}
              modifier="ghost"
              key={`legend-${index}`}
              onClick={() => handleChangeSlide(index)}
              onFocus={() => handleChangeSlide(index)}
              onKeyDown={handleKeyDown}
              eventHook={useSelectTabEvent}
              event={{ label: item.legend.label }}
              tabIndex={index === currentCardIndex ? 0 : -1}
              aria-selected={currentCardIndex === index}
              aria-controls={`tab-content-${index}`}
              role="tab"
              aria-label={item.legend.label}
              ref={(element: HTMLButtonElement) => setCurrentTabRef(element, index)}
            >
              <Typography emphasys>{item.legend.label}</Typography>
              <Image
                alt={item.legend?.icon?.alt}
                source={item.legend?.icon?.url}
                height={24}
                width={24}
                aria-hidden
              />
            </Styled.LegendButton>
          ))}
        </Styled.LegendWrapper>
      </Styled.Legend>
      {items.map((item, index) => (
        <Styled.SlideDescriptionWrapper
          id={`tab-content-${index}`}
          key={`slide-description${index}`}
          role="tabpanel"
          className={cx({ ['--show']: index === currentCardIndex })}
          tabIndex={0}
          aria-labelledby={`tab-content-description-${index}`}
          aria-hidden={index !== currentCardIndex}
          {...(index !== currentCardIndex && { hidden: true })}
        >
          <Styled.SlideDescription
            id={`tab-content-description-${index}`}
            tag={item.content.description.tag}
            text={item.content.description.text}
            accessibleText={sanitizeHTMLContent(item.content.description.text)}
          />
        </Styled.SlideDescriptionWrapper>
      ))}
      <Styled.SliderSection id="slider-section" tabIndex={-1}>
        <Styled.SlideWrapper className={cx({ ['--gray']: backgroundColor === 'gray' })}>
          {items.map((item, index) => (
            <Styled.SlideButton
              modifier="ghost"
              className={cx({ ['--selected']: currentCardIndex === index })}
              id={`carousel-item-${index}`}
              key={`carousel-item-${index}`}
              onClick={() => handleChangeSlide(index)}
              eventHook={useSelectTabEvent}
              event={{ label: item.legend.label }}
              tabIndex={-1}
            >
              <Image
                source={item.content?.image?.url}
                alt=""
                layout="fixed"
                height={280}
                width={260}
              />
            </Styled.SlideButton>
          ))}
        </Styled.SlideWrapper>
      </Styled.SliderSection>
      <Styled.Cta
        modifier="solid"
        aria-label={currentCard.content?.cta.accessibleLabel}
        href={currentCard.content.cta.url}
        event={{ label: `${currentCard.content.cta.label}-${currentCard.legend.label}` }}
      >
        {currentCard.content.cta.label}
      </Styled.Cta>
    </>
  )
}
