import {
  Container,
  type BackgroundColor,
  type ContainerProps,
  type Space,
} from '@/legacy/components/Container'
import {
  ProductSolutionCarouselComponent,
  type ProductSolutionCarouselProps,
} from '@/legacy/components/ProductSolutionCarousel'

export interface ProductSolutionCarouselBlockProps extends ProductSolutionCarouselProps {
  backgroundColor?: BackgroundColor
  backgroundColorHex?: string
  spaceOut?: Space
}

const ProductSolutionCarousel = ({
  backgroundColor,
  backgroundColorHex,
  spaceOut,
  ...props
}: ProductSolutionCarouselBlockProps) => {
  const containerProps: ContainerProps = {
    className: 'eu-sp-l',
    backgroundColor,
    backgroundColorHex,
    spaceIn: '0' as Space,
    spaceOut,
  }

  return (
    <Container {...containerProps}>
      <ProductSolutionCarouselComponent backgroundColor={backgroundColor} {...props} />
    </Container>
  )
}

export default ProductSolutionCarousel
