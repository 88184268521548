import { useEffect, useState } from 'react'

import { AccessibleText, type AccessibleTextProps } from '@/legacy/components/AccessibleText'
import { sanitizeHTMLContent } from '@/legacy/core/utils/dom/string'

interface TextComponentProps extends AccessibleTextProps {}

export const TextComponent = ({ text, ...props }: TextComponentProps) => {
  const [sanitizedText, setSanitizedText] = useState('')

  useEffect(() => {
    if (!text) return
    setSanitizedText(sanitizeHTMLContent(text) as string)
  }, [text])
  const hasText = sanitizedText && sanitizedText.length > 0

  return <>{hasText && <AccessibleText text={text} {...props} />}</>
}
