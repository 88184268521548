import styled from '@emotion/styled'

import { CONFIG } from '@/ui/shared/styles'

import { Button as ButtonComponent, ButtonLink } from '../Button'
import { HeadingM, HeadingXS } from '../Typography'

import { TextComponent } from './core/TextComponent'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: -1rem;
`

export const ComplementaryTitle = styled(HeadingXS)`
  text-align: center;
  font-weight: normal;
  margin-bottom: 0.5rem;
`
export const Title = styled(HeadingM)`
  text-align: center;
`

export const Legend = styled.div`
  display: flex;
  overflow: auto;
  margin: 4rem 0 0.5rem;
  padding-bottom: 0.5rem;
  max-height: 6.75rem;
`

export const LegendWrapper = styled.div`
  display: flex;
  margin: auto;
  gap: 1rem;
`

const RawButton = styled(ButtonComponent)`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0;
  gap: 0.5rem;
  min-width: auto;
  border: none;
  border-bottom: 4px solid transparent;
  border-radius: 0;

  & span {
    white-space: nowrap;
  }

  &:hover,
  &:focus {
    box-shadow: unset;
    background-color: unset;
  }
`

export const LegendButton = styled(RawButton)`
  display: flex;
  flex-direction: column-reverse;
  &.--selected {
    border-color: rgba(var(--theme-text-dark-high));
  }
`

export const SlideDescriptionWrapper = styled.section`
  min-height: 11rem;
  margin: 0;
  display: none;
  opacity: 0;
  height: 0;
  width: 0;
  &.--show {
    display: flex;
    justify-content: center;
    opacity: 1;
    height: auto;
    width: auto;
  }
  ${CONFIG.media.mobileMd} {
    margin: 1rem 0;
  }
`

export const SlideDescription = styled(TextComponent)`
  max-width: 31rem;
  margin: auto;
  text-align: center;
  font-size: 1.25rem;
  line-height: 2.25rem;
  ${CONFIG.media.mobileMd} {
    font-size: 1.125rem;
    min-height: 6.5rem;
  }

  & * {
    font-weight: normal;
  }

  strong {
    font-weight: bold;
  }
`

export const SliderSection = styled.section`
  display: flex;
  scroll-behavior: smooth;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 1rem;
  padding: 0 calc(50% - 8rem);
  margin-bottom: -2.825rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  min-height: 18.75rem;
  position: relative;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`

const linearGradient = (orientation: 'left' | 'right', color: 'snow' | 'palegray') =>
  `linear-gradient(to ${orientation}, transparent, rgba(var(--theme-${color})) 94%);`

export const SlideWrapper = styled.div`
  margin-top: 2.25rem;
  display: flex;
  gap: 1.5rem;

  ${CONFIG.media.md} {
    &:before,
    &:after {
      content: '';
      position: sticky;
      left: -21rem;
      width: 10rem;
      height: 100%;
      z-index: 1;
      pointer-events: none;
      padding-right: 3.5rem;
      background: ${linearGradient('left', 'snow')};
    }
    &:after {
      right: -21rem;
      background: ${linearGradient('right', 'snow')};
    }
    &.--gray {
      &:before {
        background: ${linearGradient('left', 'palegray')};
      }
      &:after {
        background: ${linearGradient('right', 'palegray')};
      }
    }
  }
`

export const SlideButton = styled(RawButton)`
  filter: drop-shadow(-6px 1px 12px rgba(0, 0, 0, 0.2));
  transition: transform 0.3s;
  transform: translateY(20%);

  &.--selected {
    transform: translateY(-1rem);
  }
`

export const Cta = styled(ButtonLink)`
  width: 18.5rem;
  margin: auto;
  text-align: center;
  min-height: 3rem;
  transform: translateY(45%);
  font-size: 1rem;
  position: relative;
  z-index: 2;
`
