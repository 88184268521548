import type { TitleProps } from '@/legacy/models'
import type { BuilderImage } from '@/legacy/models/BuilderImage'

import type { BackgroundColor } from '../Container'

import { ProductSolutionHeading } from './ProductSolutionHeading'
import { ProductSolutionSlider } from './ProductSolutionSlider'
import * as Styled from './styles'

export type CarouselItemsProps = {
  legend: {
    label: string
    icon: Pick<BuilderImage, 'url' | 'alt'>
  }
  content: {
    description: TitleProps
    image: Pick<BuilderImage, 'url' | 'alt'>
    cta: Record<'label' | 'accessibleLabel' | 'url', string>
  }
}

export type ProductSolutionCarouselProps = {
  backgroundColor?: BackgroundColor
  heading: Record<'complementaryTitle' | 'title', TitleProps>
  items: CarouselItemsProps[]
}

const ProductSolutionCarousel = ({ heading, ...props }: ProductSolutionCarouselProps) => (
  <Styled.Container>
    <ProductSolutionHeading {...heading} />
    <ProductSolutionSlider {...props} />
  </Styled.Container>
)

export default ProductSolutionCarousel
