import { useEffect, useRef, useState, type KeyboardEvent } from 'react'
import { useUpdateEffect } from 'react-use'

type UseCarouselSliderProps = {
  itemsLength: number
}

export const useCarouselSlider = ({ itemsLength }: UseCarouselSliderProps) => {
  const [currentCardIndex, setCurrentCardIndex] = useState<number>(0)
  const tabRefs = useRef<HTMLButtonElement[]>([])

  const handleSlideCards = () => {
    const elementId = `carousel-item-${currentCardIndex}`
    const element = document.getElementById(elementId)
    const container = document.getElementById('slider-section')

    if (!element || !container) return

    const elementRect = element.getBoundingClientRect()
    const containerRect = container.getBoundingClientRect()

    const getCenteredElementPosition = () =>
      elementRect.left +
      container.scrollLeft -
      containerRect.left -
      containerRect.width / 2 +
      elementRect.width / 2

    const centeredPositionOfElement = getCenteredElementPosition()

    container.scrollTo({ left: centeredPositionOfElement })
    setCurrentCardIndex(currentCardIndex)
  }

  const handleChangeSlide = (index: number) => {
    setCurrentCardIndex(index)
  }

  const goToFirstElement = () => setCurrentCardIndex(0)
  const goToLastElement = () => setCurrentCardIndex(itemsLength - 1)

  const goToPrevElement = () => {
    if (currentCardIndex === 0) return goToLastElement()
    setCurrentCardIndex(currentCardIndex - 1)
  }

  const goToNextElement = () => {
    if (currentCardIndex === itemsLength - 1) return goToFirstElement()
    setCurrentCardIndex(currentCardIndex + 1)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'ArrowRight') goToNextElement()
    else if (e.key === 'ArrowLeft') goToPrevElement()
  }

  const setCurrentTabRef = (element: HTMLButtonElement, index: number) =>
    (tabRefs.current[index] = element)

  const setTabFocus = (index: number) => tabRefs.current[index]?.focus()

  useEffect(handleSlideCards, [currentCardIndex])
  useUpdateEffect(() => setTabFocus(currentCardIndex), [currentCardIndex])

  return { handleChangeSlide, handleKeyDown, currentCardIndex, setCurrentTabRef }
}
