import type { TitleProps } from '@/legacy/models'

import * as Styled from './styles'

export const ProductSolutionHeading = ({
  title,
  complementaryTitle,
}: Record<'complementaryTitle' | 'title', TitleProps>) => (
  <>
    <Styled.ComplementaryTitle tag={complementaryTitle?.tag}>
      {complementaryTitle?.text}
    </Styled.ComplementaryTitle>
    <Styled.Title color="dark-high" tag={title?.tag}>
      {title?.text}
    </Styled.Title>
  </>
)
